(function ($) {
    // ON SCROLL
    window.Helper = (typeof Helper !== "undefined") ? Helper : {};

    Helper.scroll_to = function(el, top){
        $ = jQuery;
        top = top || 0;

        $('html, body').animate({
            scrollTop: $(el).offset().top + top
        }, 250);
    };

    $('[data-toggle="scroll"]').on('click', function(e){ 
        e.preventDefault();
        
        let $this = $(this);
        let offset = $($this).data('offset') || 0;

        $('.main').fadeIn(250, function() {});
        
        Helper.scroll_to($($($this).data('href')), offset);
    });
})(jQuery);