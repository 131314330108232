(function ($) {

    const toggle = $('[data-toggle="toggle-content"]');
    const target = $('[data-target="toggle-content"]');

    toggle.on('click', function () {
        if (target.hasClass('with-height')) {
            $(this).text('See less...')
        } else {
            $(this).text('See more...')
        }

        target.toggleClass('with-height');
    })

})(jQuery);