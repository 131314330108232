if (document.querySelectorAll('[data-component="searchbox"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const toggle = document.querySelector('[data-toggle="search-toggle"]');
        const target = document.querySelector('#search-dropdown');
        const expanded = target.getAttribute('aria-expanded');

        toggle.addEventListener('click', function () {
            target.classList.toggle('show');

            if (expanded == 'true') {
                expanded = 'false';
            } else {
                expanded = 'true';
            }

            target.setAttribute('aria-expanded', expanded)
        });
    })
}