if (document.querySelectorAll('.slick').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        $('#slickProduct').slick({
            arrows: true,
            dots: true,
            nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
            prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
        });
    
        $('#slickProductMain').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '#slickProductThumbnail'
        });

        $('#slickProductThumbnail').slick({
            asNavFor: '#slickProductMain',
            slidesToShow: 4,
            slidesToScroll: 1,
            arrows: true,
            focusOnSelect: true,
            infinite: false,
        });

        $('#slickGallery').slick({
            slidesToShow: 4,
            slidesToScroll: 2,
            arrows: true,
            dots: true,
            nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
            prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
            responsive: [{
                    breakpoint: 720,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
            ]
        });

        $('#modalStore').on('shown.bs.modal', function () {
           
            $('#slickStore').slick({
                slidesToShow: 4,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                infinite: false,
                nextArrow: '<div class="slick-next flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"/></svg></div>',
                prevArrow: '<div class="slick-prev flex items-center justify-center"><svg xmlns="http://www.w3.org/2000/svg" class="fill-red h-6" viewBox="0 0 320 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"/></svg></div>',
                responsive: [{
                        breakpoint: 720,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1
                        }
                    },
                ]
            });

            $('.content-slider').addClass('open');
        });
    })
}