if (document.querySelectorAll('[data-component="collapse"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {
        
        // Handler that uses various data-* attributes to trigger
        // specific actions, mimicing bootstraps attributes
        const triggers = Array.from(document.querySelectorAll('[data-toggle="collapse-toggle"]'));

        window.addEventListener('click', (ev) => {
            const elm = ev.target;

            if (triggers.includes(elm)) {
                const selector = elm.getAttribute('data-target');
                collapse(selector, 'toggle');
            }
        }, false);

        const fnmap = {
            'toggle': 'toggle',
            'show': 'add',
            'hide': 'remove'
        };

        const collapse = (selector, cmd) => {
            const targets = Array.from(document.querySelectorAll(selector));
            targets.forEach(target => {
                target.classList[fnmap[cmd]]('show');
            });
        }
    })
}