if (document.querySelectorAll('[data-component="quantity"]').length > 0) {
    document.addEventListener('DOMContentLoaded', function () {

        const quantityElement = $('[data-component="quantity"]');
        const inputElement = $('[data-component="quantity"] input');

        $('<div class="quantity-nav"><div class="quantity-button down"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 448 512"><path d="M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z"/></svg></div><div class="quantity-button up"><svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg></div></div>').insertAfter(inputElement);

        quantityElement.each(function () {
            var spinner = $(this);
            var inputNumber = spinner.find('input[type="number"]');
            var buttonIncrease = spinner.find('.up');
            var buttonDecrease = spinner.find('.down');
            var inputNumberMinimum = inputNumber.attr('min');
            var inputNumberMaximum = inputNumber.attr('max');

            buttonIncrease.on('click', function () {
                var oldValue = parseFloat(inputNumber.val());
                
                if (oldValue >= inputNumberMaximum) {
                    var newValue = oldValue;
                } else {
                    var newValue = oldValue + 1;
                }

                spinner.find('input').val(newValue);
                spinner.find('input').trigger('change');
            })

            buttonDecrease.on('click', function () {
                var oldValue = parseFloat(inputNumber.val());

                if (oldValue <= inputNumberMinimum) {
                    var newValue = oldValue;
                } else {
                    var newValue = oldValue - 1;
                }

                spinner.find('input').val(newValue);
                spinner.find('input').trigger('change');
            })
        })
    })
}